gsap.config({
    nullTargetWarn: false,
    trialWarn: false,
})

import './styles/main.scss'
import './styles/index.scss'
import './styles/about.scss'
import './styles/richard.scss'
import './styles/sam.scss'
import './styles/oliver.scss'
import './styles/contact.scss'

import barba from '@barba/core'
import gsap from "gsap"
import {
    indexEnter,
    indexLeave,
    aboutEnter
} from './animations'

barba.init({
    transitions: [{
        name: 'default-transition',
        leave(data) {
            return gsap.to(data.current.container, {
                opacity: 0,
                duration: 1
            })
        },
        enter(data) {
            return gsap.from(data.next.container, {
                opacity: 0,
                duration: 1
            })
        }
    }]
})